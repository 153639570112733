import Api from "../commom/api";
import {MapDetailsInterface} from "../commom/types";

const mapAPI = async (evseID: string) => {
    const response = await Api.get<MapDetailsInterface>('/evse/' + evseID + '/get-map-details/')

    return response.data
}

export {mapAPI}
