import React from 'react'
import { useState, useEffect } from 'react';

const TimerComponent = (props:any) => {
    const {initialMinute = 0,initialSeconds = 59} = props;
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    useEffect(()=>{
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
        };
    });

    return (
        <div className="dark:text-white flex flex-wrap items-center justify-center mt-10 mr-10">

            { minutes === 0 && seconds === 0
                ? null
                : <span className="countdown text-6xl"> {seconds < 10 ?  `0${seconds}` : seconds}</span>
            }
        </div>
    )
}

export default TimerComponent;