import React from 'react';
import './index.css'
import {Router} from "@reach/router";
import TariffPage from "./pages/tariff.page";
import DefaultPage from "./pages/default.page";
import SuccessPage from "./pages/success.page";
import CancelPage from "./pages/cancel.page";
import DetailsPage from "./pages/details.page";
import ContainerPage from "./pages/container.page";
import 'flowbite';
import 'react-loading-skeleton/dist/skeleton.css'

class App extends React.Component {


    render() {

        return (
            <>
                <div className="dark:bg-gray-900 bg-gray-300">
                    <Router>

                        <DefaultPage default/>

                        <ContainerPage path="con/:evseID">
                            <DefaultPage default/>

                            <DetailsPage path="*/"/>
                            <TariffPage path="tariff/"/>
                            {/*<SuccessPage path="success/"/>*/}
                            <SuccessPage path="success/session/:sessionID"/>
                            <CancelPage path="canceled"/>
                        </ContainerPage>

                    </Router>
                </div>

            </>
        );
    }
}

export default App;
