import Api from "../commom/api";
import {PaymentStatusInterface} from "../commom/types";

const paymentStatusAPI = async (connectorID: string, sessionID: string) => {
    const response = await Api.get<PaymentStatusInterface>('/evse/' + connectorID + '/check-payment-status/' + sessionID + "/")

    return response.data
}

export {paymentStatusAPI}
