import Api from "../commom/api";
import {NewSessionRequestInterface, NewSessionResponseInterface} from "../commom/types";
import Response from "../commom/response";

const submitPayment = async (captchaToken: string, evseID: string) => {

    let request: NewSessionRequestInterface = {
        captchaToken: captchaToken,
        evseID: evseID,
    };


    return await Api.post<NewSessionResponseInterface, object>('/evse/' + evseID + '/create-payment/', request)
        .then(response => {
            return new Response(true, response, "None", "")
        })
        .catch(function (error) {
            return new Response(false, null, "Error", error);
        });
}

export {submitPayment}
