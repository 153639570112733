import * as React from "react";

interface FailAlertProps {
    message: string
}

const FailAlert: React.FC<FailAlertProps> = ({message}) => {

    return (
        <div id="alert-border-2"
             className="flex p-4 mb-4 bg-red-100 border-t-4 border-red-500 dark:bg-red-200  "
             role="alert">
            <svg className="flex-shrink-0 w-5 h-5 text-red-700" fill="currentColor"
                 viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd">

                </path>
            </svg>
            <div className="ml-3 text-sm font-medium text-red-700">
                {message}
            </div>

        </div>
    )
}
export default FailAlert