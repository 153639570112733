import axios, {AxiosInstance, AxiosRequestHeaders} from 'axios';
import configData from "../config.json";

const goURL: string = (configData.SERVER_URL as string)
const authKey: string = (configData.AUTH_KEY as string)

class Api {
    private static axiosInstance: AxiosInstance;

    private static headers: AxiosRequestHeaders;

    static init() {
        this.axiosInstance = axios.create({
            baseURL: goURL + "/api"
        })

        this.headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
            'Authorization': 'key=' + authKey
        };
    }

    static async get<ResponseType>(url: string) {

        return await Api.axiosInstance.get<ResponseType>(url, {headers: this.headers})
    }

    static async post<ResponseType, DataType>(url: string, request?: DataType) {

        return Api.axiosInstance.post<ResponseType, DataType>(url, {request}, {headers: this.headers})
    }
}

export default Api;
