import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestion} from "@fortawesome/free-solid-svg-icons";
import {AppCtx} from "../commom/app.context";
import formatHTML from "../components/helpers";
import LoaderComponent from "../components/loader.compoent";
import {useTranslation} from "react-i18next";

const InfoPopup: React.FC = () => {

    const {t, i18n: {language}} = useTranslation(['common', 'info']);


    const [showModal, setShowModal] = React.useState(false);

    const appCtx = useContext(AppCtx);

    const [infoText, setText] = React.useState("");


    const handleClick = async (val: boolean) => {
        setShowModal(val)

        if (appCtx?.connector?.cpoName !== undefined) {

            const subPartnerName: string = appCtx?.connector?.cpoName

            let fileName = subPartnerName.replace(/ /g, "_");

            // if language contains en or EN, add _en to the filename
            if (language.toLowerCase().includes("en")) {
                fileName = fileName + "_en"
            }

            try {
                const file = await fetch(window.location.origin + "/" + fileName + '.json', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cache-Control': 'no-cache',
                        'Pragma': 'no-cache',
                        'Expires': '0',
                    }
                })

                const text = await file.text()

                const json = JSON.parse(text)
                let jsonArray = json.info.split("\n")
                let formatted = jsonArray
                    .map((texts: string, i: number) => formatHTML(texts, i))
                setText(formatted)

            } catch (e) {
                console.log(e)
                setText(t("common:failed_to_load_data"))
            }

        }
    }
    return (
        <>

            {
                appCtx?.connector?.cpoName === undefined ?
                    <button type="button"
                            className="
                  mx-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <FontAwesomeIcon icon={faQuestion}/>
                    </button> : <button type="button"
                                        onClick={() => handleClick(true)}
                                        className="
                      mx-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <FontAwesomeIcon icon={faQuestion}/>
                    </button>
            }


            {showModal ? (
                <>
                    <div id="defaultModal" aria-hidden="true"
                         className="mt-2 max-w-sm max-w-md overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full">
                        <div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                <div
                                    className="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white">
                                        {t('info:title')}
                                    </h3>
                                    <button type="button" onClick={() => setShowModal(false)}
                                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                            data-modal-toggle="defaultModal">
                                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </div>
                                <div className="p-6 space-y-6">
                                    {infoText === "" ?
                                        <div className="flex justify-center"><LoaderComponent/></div> : infoText}
                                </div>
                                <div
                                    className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                                    <button data-modal-toggle="defaultModal" type="button"
                                            onClick={() => setShowModal(false)}
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>


                </>
            ) : null}
        </>
    );

}
export default InfoPopup;
