import configData from "./config.json";

export const RECAPTCHA_KEY: string = (configData.GOOGLE_CAPTCHA_SITE_KEY as string)

export const isBrowser = typeof window !== 'undefined';

export interface IGoogleReCaptcha {
    ready: (callback: () => void) => void;
    execute: (siteKey: string, options?: { action: string }) => Promise<string>;
}

// export interface IProjectWindow extends Window {
//     grecaptcha: IGoogleReCaptcha;
// }
