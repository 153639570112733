import * as React from "react";
import {RECAPTCHA_KEY} from "../definitions";

declare global {
    interface Window {
        captchaOnLoad: () => void
        grecaptcha: ReCaptchaInstance
    }
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => any
    execute: (options: ReCaptchaExecuteOptions) => Promise<string>
    render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
    action: string
}

interface ReCaptchaRenderOptions {
    sitekey: string
    size: 'invisible'
}

interface State {
    readonly isReady: boolean
}


class ReCaptcha extends React.PureComponent {

    state: State = {
        isReady: false
    }

    private script: HTMLScriptElement | undefined
    private widget: HTMLDivElement | undefined

    componentDidMount(): void {
        this.loadScript()
    }

    componentWillUnmount(): void {
        if (this.widget)
            document.body.removeChild(this.widget)

        if (this.script)
            document.body.removeChild(this.script)
    }

    render(): React.ReactNode {
        // @ts-ignore
        return this.props.children({
            isReady: this.state.isReady,
            execute: this.executeCaptcha
        })
    }

    private loadScript = (): void => {
        window.captchaOnLoad = this.onLoad

        const url = 'https://www.google.com/recaptcha/api.js'
        const queryString = '?onload=captchaOnLoad&render=explicit'
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url + queryString
        script.async = true
        script.defer = true

        this.script = document.body.appendChild(script)
    }

    private onLoad = (): void => {
        const widget = document.createElement('div')
        widget.id = 'g-recaptcha'
        this.widget = document.body.appendChild(widget)

        window.grecaptcha.render('g-recaptcha', {
            sitekey: RECAPTCHA_KEY,
            size: 'invisible'
        })

        window.grecaptcha.ready(() => {
            this.setState({isReady: true})
        })
    }

    private executeCaptcha = (): Promise<string> => {
        if (!this.state.isReady) {
            throw new Error("Captcha can be executed only when it's ready.")
        }

        return window.grecaptcha.execute({action: "handleClick"})
    }
}

export interface CaptchaState {
    token: string | undefined
    loading: boolean
    fail: boolean
    failMessage: string | undefined
}

export default ReCaptcha
