import React, {useEffect} from "react";
import {ConnectorStatus, paramProps} from "../commom/types";
import L from "leaflet";
import {mapAPI} from "../service/map.api";

const MapComponent: React.FC<paramProps> = ({evseID}) => {

    const greenIcon = L.icon({
        iconUrl: window.location.origin + '/marker_available.png',
        iconSize: [42, 42], // size of the icon
        shadowSize: [50, 64], // size of the shadow
    });
    const redIcon = L.icon({
        iconUrl: window.location.origin + '/marker_offline.png',
        iconSize: [42, 42], // size of the icon
    });
    const blueIcon = L.icon({
        iconUrl: window.location.origin + '/marker_charging.png',
        iconSize: [42, 42], // size of the icon
        shadowSize: [50, 64], // size of the shadow
    });
    const greyIcon = L.icon({
        iconUrl: window.location.origin + '/marker_unknown.png',
        iconSize: [42, 42], // size of the icon
        shadowSize: [50, 64], // size of the shadow
    });


    useEffect(() => {
        const fetch = async () => {

            const resp = await mapAPI(evseID)

            const latitude = resp.lat
            const longitude = resp.long

            const map = L.map('map').setView([latitude, longitude], 13);

            L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                className: 'map-tiles',
            }).addTo(map);

            switch (resp?.status) {

                case ConnectorStatus.Available :
                case ConnectorStatus.Preparing:
                    L.marker([latitude, longitude], {icon: greenIcon}).addTo(map);
                    break;
                case ConnectorStatus.Offline:
                case ConnectorStatus.Unavailable:
                    L.marker([latitude, longitude], {icon: redIcon}).addTo(map);
                    break;
                case ConnectorStatus.Charging :
                case ConnectorStatus.Reserved:
                case ConnectorStatus.Finishing:
                    L.marker([latitude, longitude], {icon: blueIcon}).addTo(map);
                    break;

                default:
                    L.marker([latitude, longitude], {icon: greyIcon}).addTo(map);
                    break;

            }


        }

        if (evseID !== undefined) {
            fetch()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div id="map" className="rounded-lg shadow-md"/>
        </>
    )
}

export default MapComponent