import {ConnectorDetailsInterface, MainDetailsInterface, PricingDetailsInterface} from "./types";
import React from "react";


export interface ContextInterface {
    evseID?: string;
    sessionID?: string
    title?: string
    address?: MainDetailsInterface
    addressReady: boolean
    price?: PricingDetailsInterface
    connector?: ConnectorDetailsInterface
    connectorReady?: boolean
    connectorUpdatedAt?: Date
    priceReady: boolean
}

export const AppCtx = React.createContext<ContextInterface | null>(null);


export default function CreateCtx<ContextInterface extends {} | null>() {
    const ctx = React.createContext<ContextInterface | undefined>(undefined);

    function useCtx() {
        const c = React.useContext(ctx);
        if (c === undefined)
            throw new Error("useCtx must be inside a Provider with a value");
        return c;
    }

    return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}

