import React, {useEffect, useState} from "react";
import TopComponent from "../components/top.component";
import HeaderComponent from "../components/header.component";
import 'leaflet/dist/leaflet.css';
import {ConnectorDetailsInterface, MainDetailsInterface, PricingDetailsInterface} from "../commom/types";
import {addressAPI} from "../service/address.api";
import {priceAPI} from "../service/price.api";
import {Helmet} from "react-helmet";
import {validatorAPI} from "../service/validator.api";
import DefaultPage from "./default.page";
import {connectorDetailsAPI} from "../service/details.api";
import CreateCtx, {AppCtx, ContextInterface} from "../commom/app.context";
import {CustomRouterProps} from "../commom/props";
import CookieAlert from "../components/cookie.component";

import TranslateComponent from "../components/translate.component";


const ContainerPage: React.FC<CustomRouterProps> = ({evseID, ...rest}) => {

    const [validate, validateSet] = useState<boolean | false>()

    const [isValidating, setIsValidating] = useState(false);

    const [firstRun, setFirstRun] = useState(1);


    const [address, addressSet] = useState<MainDetailsInterface | undefined>()

    const [isLoadingAddress, setIsLoadingAddress] = useState(false);


    const [price, priceSet] = useState<PricingDetailsInterface | undefined>()

    const [isLoadingPrice, setIsLoadingPrice] = useState(false);


    const [connector, setConnector] = useState<ConnectorDetailsInterface | undefined>()

    const [isLoadingConnector, setIsLoadingConnector] = useState(false);


    const sysCtx: ContextInterface = {
        evseID: evseID,
        address: address,
        price: price,
        connector: connector,
        addressReady: isLoadingAddress,
        priceReady: isLoadingPrice,
        connectorReady: isLoadingConnector,
        connectorUpdatedAt: undefined
    };

    // @ts-ignore
    useEffect(() => {
        // validate evse

        let resp
        const validateEvse = async (id: string) => {
            try {
                setIsValidating(true)
                resp = await validatorAPI(id)
                validateSet(resp)
                setIsValidating(false)

                setFirstRun(2)
                if (resp === true) {
                    fetchAllData(id)

                }

            } catch (error) {


                console.log("validating failed", error);
            }
        };

        if (!isValidating && evseID !== undefined) {
            validateEvse(evseID)
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function fetchAllData(id: string) {

        // fetch address
        const fetchData = async (id: string) => {
            try {
                setIsLoadingAddress(true)
                const resp = await addressAPI(id)
                addressSet(resp)
                setIsLoadingAddress(false)

                sysCtx.address = resp

            } catch (error) {

                console.log("error fetching address", error);
            }
        };

        /// fetch price

        const fetchPrice = async (id: string) => {

            try {
                setIsLoadingPrice(true)
                const resp = await priceAPI(id)
                priceSet(resp)
                setIsLoadingPrice(false)

                sysCtx.price = resp

            } catch (error) {
                console.log("error fetching price ", error);
            }
        }

        const fetchConnector = async (id: string) => {
            try {
                setIsLoadingConnector(true)
                const resp = await connectorDetailsAPI(id)
                setConnector(resp)
                setIsLoadingConnector(false)

                sysCtx.connector = resp
                sysCtx.connectorUpdatedAt = new Date()


            } catch (error) {
                console.log("error fetching connector data", error);
            }
        };

        if (!isLoadingAddress && !isLoadingPrice && !isLoadingConnector && evseID !== undefined) {
            try {
                fetchData(evseID)
                fetchPrice(evseID)
                fetchConnector(evseID)
                CreateCtx()
            } catch (e) {
                console.log("exception", e)
            }
        }
    }


    if (!validate && !isValidating && firstRun !== 1) {
        return <DefaultPage/>
    }
    return (
        <>
            <Helmet>
                <title>{evseID}</title>
            </Helmet>
            <div
                className=" p-6 max-w-lg bg-white rounded-xl dark:bg-gray-800 pb-24 dark:bg-gray-800 dark:border-gray-700">
                <div className="relative">

                    <AppCtx.Provider value={sysCtx}>

                        <TopComponent evseID={evseID}/>

                        <HeaderComponent evseID={evseID}/>

                        {rest.children}


                    </AppCtx.Provider>
                    <CookieAlert/>
                </div>

                <TranslateComponent />


            </div>

        </>
    )

}
export default ContainerPage