import React from 'react';
import AGBPopup from "../popups/agb.popup";
import GdprPopup from "../popups/gdpr.popup";
import {useTranslation} from "react-i18next";


const CheckoutText: React.FC = () => {

    const {t} = useTranslation(['common', 'tariff']);


    return (
        <>
            <div className="pt-10 pb-5 ml-2 ">

                <p className="dark:text-gray-100 mb-10">

                    {(t('tariff:first_paragraph'))}

                </p>

                <p>
                    {(t('tariff:second_paragraph'))}

                </p>
                <h2>
                    {(t('tariff:third_paragraph'))} <AGBPopup/> {t('common:and')} <GdprPopup/>
                </h2>

            </div>

        </>

    );
}
export default CheckoutText;
