import Api from "../commom/api";
import {EndSessionInterface} from "../commom/types";

const endSessionApi = async (evseID: string, sessionID: string) => {
    const response = await Api.get<EndSessionInterface>('/evse/' + evseID + '/end-session/' + sessionID + "/")

    return response.data
}

export {endSessionApi}
