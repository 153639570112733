import React, {useContext, useEffect, useState} from 'react';
import {ConnectorStatus, paramProps} from "../commom/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChargingStation} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import {AppCtx} from "../commom/app.context";
import moment from 'moment';
import 'moment/locale/de';
import {TFunction, useTranslation} from "react-i18next";


const HeaderComponent: React.FC<paramProps> = ({evseID}) => {

    const appCtx = useContext(AppCtx);

    const MINUTE_MS = 60000;

    const [lastUpdated, setLastUpdated] = useState("");

    const {t, i18n} = useTranslation(['header', 'status']);

    i18n.on('languageChanged', () => {
        setLastUpdatedTime();

    });

    const setLastUpdatedTime = () => {
        const dateTimeAgo = moment(appCtx?.connectorUpdatedAt).locale(i18n.language);

        if (i18n.language.toLowerCase().includes("en")) {
            setLastUpdated(t('header:ago') + " " + dateTimeAgo.fromNow())

            setInterval(() => {
                setLastUpdated(t('header:ago') + " " + dateTimeAgo.fromNow())

            }, MINUTE_MS);

        } else {
            setLastUpdated(dateTimeAgo.fromNow() + " " + t('header:ago'))

            setInterval(() => {
                setLastUpdated(dateTimeAgo.fromNow()+ " " + t('header:ago'))

            }, MINUTE_MS);
        }

    };
    useEffect(() => {

        setLastUpdatedTime();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="rounded-lg shadow-md mt-5 pl-6 pr-2">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-200  ">
                <span className={appCtx?.connector ? "" : "hidden"}>
                    <FontAwesomeIcon icon={faChargingStation}/> &nbsp;</span>

                {appCtx?.connector?.stationName || <Skeleton className="animate-pulse"/>}
            </h5>


            <div className="flex flex-row justify-between grid-cols-1 font-medium xs:flex-wrap pt-5 ">

                <div className="flex flex-col w-2/3">
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-100">
                        {t('header:connector')} {appCtx?.connector?.port || <Skeleton className="animate-pulse"/>}
                    </p>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-100">
                        {appCtx?.connector?.connectorId || <Skeleton className="animate-pulse"/>}
                    </p>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-100">
                        {appCtx?.connector?.cpoName || <Skeleton className="animate-pulse"/>}
                    </p>
                </div>


                <div className="flex flex-col justify-center items-center w-1/3">
                    <span className=" ml-4">
                        {convertStatus(t, appCtx?.connector?.connectorStatus) || <Skeleton className="animate-pulse"/>}
                    </span>

                    {!lastUpdated || <span
                        className=" ml-6 mt-2 font-normal text-gray-700 dark:text-gray-400 text-xs inline-block justify-items-end">
                        {lastUpdated}
                    </span>
                    }

                </div>

            </div>


        </div>

    );
}
export default HeaderComponent;


function convertStatus(t: TFunction<("header" | "status")[], undefined>, status: ConnectorStatus | undefined) {
    if (status === undefined) {
        return <span
            className="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                  <Skeleton className="animate-pulse"/>
            </span>
    }
    switch (status) {
        case "Offline":
        case "Unavailable":
            return <span
                className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">
                {translateStatus(t, status)}
            </span>;

        case "Available":
        case "Preparing":

            return <span
                className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
             {translateStatus(t, status)}

            </span>

        case "Charging":
        case "Reserved":
        case "Finishing":
            return <span
                className="bg-indigo-100 text-indigo-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-indigo-900">
                {translateStatus(t, status)}
            </span>

        default:
            return <span
                className="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                {translateStatus(t, status)}
            </span>


    }
}

function translateStatus(t: TFunction<("header" | "status")[], undefined>, status: ConnectorStatus) {
    switch (status) {
        case ConnectorStatus.Available :
        case ConnectorStatus.Preparing:
            return t('status:available')

        case ConnectorStatus.Charging:
        case ConnectorStatus.Reserved:
        case ConnectorStatus.Finishing:
        case ConnectorStatus.SuspendedEV:
        case ConnectorStatus.SuspendedEVSE:
            return t('status:occupied')

        case ConnectorStatus.Offline:
        case ConnectorStatus.Unavailable:
            return t('status:offline')

        case ConnectorStatus.EVCommunicationError:
            return t('status:communication_error')

    }

}