import React, {useEffect, useState} from 'react';
import PriceComponent from "./../components/price.component";

import CaptchaCheckoutForm from "../components/captcha.form.component";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";

const TariffPage: React.FC<any> = ({evseID}) => {

    const {t} = useTranslation(['common', 'tariff', 'price']);

    // const [token, tokenSet] = useState<CSRFToken | undefined>()

    const [isSetting, setIsSetting] = useState(false);


    useEffect(() => {
        // fetch token
        const fetchToken = async (id: string) => {
            try {
                setIsSetting(true)
                setIsSetting(false)


            } catch (error) {
                console.log("error", error);
            }
        };

        if (!isSetting && evseID !== undefined) {
            fetchToken(evseID)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title>{evseID} {t('tariff:title')}</title>
            </Helmet>
            <div className="flex flex-row pt-5 tracking-tight text-gray-900 dark:text-gray-200">
                <div className="pt-5 pr-5">
                    {/*todo: add plug type icon*/}

                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                              d="M14.121 15.536c-1.171 1.952-3.07 1.952-4.242 0-1.172-1.953-1.172-5.119 0-7.072 1.171-1.952 3.07-1.952 4.242 0M8 10.5h4m-4 3h4m9-1.5a9 9 0 11-18 0 9 9 0 0118 0z"/>
                    </svg>

                </div>
                <div className="">
                    <PriceComponent evseID={evseID} children={t('price:info')}/>
                </div>
            </div>


            <div className="flex flex-row pt-2">

                <div className="tracking-tight text-gray-900 dark:text-gray-200">

                    <CaptchaCheckoutForm evseID={evseID}/>

                </div>
            </div>
        </>

    );
}
export default TariffPage;
