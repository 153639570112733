import React from "react";
import {Helmet} from "react-helmet";
import TopComponent from "../components/top.component";
import TranslateComponent from "../components/translate.component";
import {useTranslation} from "react-i18next";

const DefaultPage: React.FC<any> = () => {

    const {t} = useTranslation(['common', 'error']);

    return <>
        <Helmet>
            <title>{t("error:404.title")}</title>
        </Helmet>

        <div
            className="p-6 max-w-sm max-w-md bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">

            <TopComponent evseID={""}/>
            <div className="px-40 pt-20 pb-10 rounded-md">
                <div className="flex flex-col items-center">
                    <h1 className="font-bold text-blue-600 text-9xl">404</h1>


                </div>
            </div>

            <div className="flex flex-col items-center">

                <h6 className="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                    <span className="text-red-500">{t("error:404.heading")}</span>
                </h6>

                <p className="mb-8 text-center text-gray-500 md:text-lg">
                    {t("error:404.description")}
                </p>

            </div>
            <TranslateComponent/>

        </div>
    </>
}
export default DefaultPage;
