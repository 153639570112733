import Api from "../commom/api";
import {SessionStatusInterface} from "../commom/types";

const sessionStatusAPI = async (evseID: string, sessionID: string) => {
    const response = await Api.get<SessionStatusInterface>('/evse/' + evseID + '/check-session-status/' + sessionID + "/")

    return response.data
}

export {sessionStatusAPI}
