import React, {useContext, useEffect, useState} from "react";
import {paramProps} from "../commom/types";
import {AppCtx} from "../commom/app.context";
import Skeleton from "react-loading-skeleton";
import {useTranslation} from "react-i18next";

const PriceComponent: React.FC<paramProps> = ({evseID, children}) => {

    const {t, i18n} = useTranslation(['common', 'price']);

    const [perTransactionPrice, setPerTransactionPrice] = useState("0");
    const [perMinutePrice, setPerMinutePrice] = useState("0");
    const [perKWHPrice, setPerKWHPrice] = useState("0");
    const [blockingFee, setBlockingFee] = useState("0");


    const appCtx = useContext(AppCtx);

    useEffect(() => {
        const price = appCtx?.price?.perTransaction || 0;

        if (i18n.language === 'de') {
            setPerTransactionPrice(price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}))
        } else {
            setPerTransactionPrice(price.toLocaleString('en-US', {style: 'currency', currency: 'EUR'}))
        }

    }, [i18n.language, appCtx?.price?.perTransaction])

    useEffect(() => {
        const price = appCtx?.price?.perMinute || 0;

        if (i18n.language === 'de') {
            setPerMinutePrice(price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}))
        } else {
            setPerMinutePrice(price.toLocaleString('en-US', {style: 'currency', currency: 'EUR'}))
        }

    }, [i18n.language, appCtx?.price?.perMinute])

    useEffect(() => {
        const price = appCtx?.price?.perKWH || 0;

        if (i18n.language === 'de') {
            setPerKWHPrice(price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}))
        } else {
            setPerKWHPrice(price.toLocaleString('en-US', {style: 'currency', currency: 'EUR'}))
        }

    }, [i18n.language, appCtx?.price?.perKWH])

    useEffect(() => {
        const price = appCtx?.price?.blockingFee || 0;

        if (i18n.language === 'de') {
            setBlockingFee(price.toLocaleString('de-DE', {style: 'currency', currency: 'EUR'}))
        } else {
            setBlockingFee(price.toLocaleString('en-US', {style: 'currency', currency: 'EUR'}))
        }

    }, [i18n.language, appCtx?.price?.blockingFee])

    return (

        <>
            <div className="flex flex-col">
                <div>

                    {appCtx?.price?.perTransaction !== undefined || <Skeleton count={1} className="animate-pulse"/>}

                    <span
                        className={appCtx?.price?.perTransaction !== undefined && appCtx?.price?.perTransaction !== 0 ? "" : "hidden"}> {appCtx?.price?.perTransaction !== 0 ? perTransactionPrice + " " + t('price:fee') : ""}</span>
                </div>
                <div>
                    {appCtx?.price?.perKWH !== undefined || <Skeleton count={1} className="animate-pulse"/>}

                    <span
                        className={appCtx?.price?.perKWH !== undefined && appCtx?.price?.perKWH !== 0 ? "" : "hidden"}> {appCtx?.price?.perKWH !== 0 ? perKWHPrice + " " + t('price:energy') : ""}</span>

                </div>
                <div>
                    {appCtx?.price?.perMinute !== undefined || <Skeleton count={1} className="animate-pulse"/>}

                    <span
                        className={appCtx?.price?.perMinute !== undefined && appCtx?.price?.perMinute !== 0 ? "" : "hidden"}> {appCtx?.price?.perMinute !== 0 ? perMinutePrice + " " + t('price:minute') : ""}</span>

                </div>
                <div>

                    {appCtx?.price?.blockingFee !== undefined || <Skeleton count={1} className="animate-pulse"/>}

                    <span
                        className={appCtx?.price?.blockingFee !== undefined && appCtx?.price?.blockingFee !== 0 ? "" : "hidden"}> {appCtx?.price?.blockingFee !== 0 ? blockingFee + t('price:blocking') : ""}</span>

                </div>

                {appCtx?.price?.perTransaction !== 0 || appCtx?.price?.perKWH !== 0 || appCtx?.price?.perMinute !== 0 || appCtx?.price?.blockingFee !== 0 ? children :

                    <div className="mt-5">
                        {t('price:free')}
                    </div>
                }

            </div>
        </>
    );
}

export default PriceComponent
