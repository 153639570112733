export interface CSRFToken {
    token: string
}

export interface ConnectorDetailsInterface {
    connectorId: string
    connectorStatus: ConnectorStatus
    connectorPlugType: string
    connectorPower: number
    connectorAmpere: number
    stationName: string
    cpoName: string
    port: number
}

export enum ConnectorStatus {
    Offline = "Offline",
    Unavailable = "Unavailable",
    Available = "Available",
    Preparing = "Preparing",
    Charging = "Charging",
    Reserved = "Reserved",
    Finishing = "Finishing",
    SuspendedEV = "SuspendedEV",
    SuspendedEVSE = "SuspendedEVSE",
    EVCommunicationError = "EVCommunicationError",
    Faulted = "Faulted"
}

export interface PricingDetailsInterface {
    perMinute: number
    perKWH: number
    perTransaction: number
    blockingFee: number
}

export interface DesignInterface {
    cpoName: string
    lightLogo: string
    darkLogo: string
}

export interface MainDetailsInterface {
    street: string
    postCode: number
    houseNumber: string
    city: string
    country: string
    phone: string
    lat: number
    long: number
}

export interface PaymentStatusInterface {
    sessionID: string
    status: PaymentStatus
    message: string
}

export enum PaymentStatus {
    Success = "success",
    Declined = "declined",
    Expired = "expired",
    Authorized = "authorized",
    Pending = "pending",
    Rejected = "rejected"
}

export interface SessionStatusInterface {
    sessionID: string
    status: SessionStatus
    message: string
}

export interface EndSessionInterface {
    sessionID: string
    status: EndSessionStatus
}

export enum EndSessionStatus {
    Ended = "ended",
    Errored = "errored",
}

export enum SessionStatus {
    Active = "Active",
    Pending = "Pending",
    Finished = "Finished",
    Invalid = "Invalid",
    Error = "Error"
}

export interface MapDetailsInterface {
    lat: number
    long: number
    status: ConnectorStatus
}


export interface NewSessionRequestInterface {
    captchaToken: string
    evseID: string
}

export interface NewSessionResponseInterface {
    status: string
    message: string
    redirectURL: string
}

export interface paramProps {
    evseID: any
}
