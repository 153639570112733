import Api from "../commom/api";
import {MainDetailsInterface} from "../commom/types";

const addressAPI = async (evseID: string) => {
    const response = await Api.get<MainDetailsInterface>('/evse/' + evseID + '/get-location-details/')

    return response.data
}

export {addressAPI}
