import Api from "../commom/api";
import {ConnectorDetailsInterface} from "../commom/types";

const connectorDetailsAPI = async (evseID: string) => {

    const response = await Api.get<ConnectorDetailsInterface>('/evse/' + evseID + '/get-connector-details/')
    return response.data
}

export {connectorDetailsAPI}
