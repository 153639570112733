import React, {useEffect, useState} from 'react';
import {DesignInterface, paramProps} from "../commom/types";
import {designAPI} from "../service/design.api";
import {Link} from "@reach/router";

const TopComponent: React.FC<paramProps> = ({evseID}) => {

    const [design, designSet] = useState<DesignInterface | null>(null)

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchData = async (id: string) => {
            setIsLoading(true)
            const resp = await designAPI(id)
            designSet(resp)

            return resp
        };

        if (!isLoading && evseID !== undefined && evseID !== "") {
            fetchData(evseID).then((resp) => {
                designSet(resp)
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)

            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <div
                className="dark:hidden block max-w-sm max-w-md bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded">
                <div className="container flex flex-wrap items-left mx-auto">


                    {design ?
                        <div className="w-20 h-50">
                            <Link to={"/con/" + evseID} className="flex">
                                <img id="logo" src={window.location.origin + "/" + design?.lightLogo}
                                     alt={design?.cpoName}
                                     className={design?.lightLogo.split(".")[0]} title={design?.cpoName}/>

                            </Link>
                        </div>
                        : <span
                            className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Adhoc-Charge</span>
                    }

                </div>
            </div>

            <div
                className="hidden dark:block max-w-sm max-w-md border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-800">
                <div className="container flex flex-wrap items-left mx-auto">


                    {design ?
                        <div className="w-20 h-50">
                            <Link to={"/con/" + evseID} className="flex">
                                <img id="logo" src={window.location.origin + "/" + design?.darkLogo}
                                     alt={design?.cpoName}
                                     className={design?.darkLogo.split(".")[0]} title={design?.cpoName}/>

                            </Link>
                        </div>
                        : <span
                            className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Adhoc-Charge</span>
                    }

                </div>
            </div>
        </>

    );
}
export default TopComponent;
