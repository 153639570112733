import * as React from 'react'
import {Link} from "@reach/router";
import {submitPayment} from "../service/form.api";
import InfoPopup from "../popups/info.popup";
import CheckoutText from "./checkout.text";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {connectorDetailsAPI} from "../service/details.api";
import {ConnectorStatus} from "../commom/types";
import ReCaptcha from "../commom/recaptcha";
import {useContext, useEffect, useState} from "react";
import {CustomRouterProps} from "../commom/props";
import FailAlert from "./fail.alert";
import {useTranslation} from "react-i18next";
import {useCookies} from "react-cookie";
import {AppCtx} from "../commom/app.context";

const CaptchaCheckoutForm: React.FC<CustomRouterProps> = ({evseID}) => {

    const {t} = useTranslation(['common', 'checkout']);

    const appCtx = useContext(AppCtx);

    const handleCheckout = async (e: React.MouseEvent<HTMLButtonElement>, execute: () => Promise<string>) => {
        e.preventDefault()
        setLoading(true)

        const token = await execute()

        setLoading(true)
        setToken(token)


        if (evseID)
            processCheckout(evseID, token)
    }

    const [cookies, setCookie] = useCookies(['session_info'])

    useEffect(() => {

        if (cookies.session_info?.sessionID) {

            if (appCtx?.connector?.connectorStatus !== ConnectorStatus.Charging) {
                // delete cookie
                setCookie('session_info', undefined, {path: '/'})
            }


        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const [loading, setLoading] = useState<boolean | false>()
    const [fail, setFail] = useState<boolean | false>()

    const [failMessage, setFailMessage] = useState("")

    const [token, setToken] = useState("");


    const processCheckout = (connectorID: string, captchaToken: string) => {

        const fetch = async () => {

            // fetch connector details
            const connectorDetails = await connectorDetailsAPI(connectorID)

            if (connectorDetails.connectorStatus !== ConnectorStatus.Available && connectorDetails.connectorStatus !== ConnectorStatus.Preparing) {

                setLoading(false)
                setFail(true)

                setFailMessage(t('checkout:charge_point_unavailable'))

                return
            }


            // create payment link
            const resp = await submitPayment(captchaToken, connectorID)

            if (resp.Data?.data.status === true) {
                window.location.href = resp.Data?.data.redirectURL;

                return
            }

            setLoading(false)
            setFail(true)

            setFailMessage(t('checkout:server_error'))

        }

        fetch()
    }

    return (
        <>
            <ReCaptcha>
                {(captcha: { execute: () => Promise<string>; isReady: { toString: () => boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined } }) => (
                    <form>
                        <div>
                            <CheckoutText/>

                            <input
                                type="hidden" value={token || ''} onChange={() => {
                            }} id="recaptcha-key" name="g-recaptcha-response"/>

                            <input type="hidden" id="token" name="token"/>


                            <div className={fail ? "" : "hidden"}>

                                <FailAlert message={failMessage}/>

                            </div>

                            {/*Bottom Nav*/}
                            <div className="flex justify-between">

                                <Link type="button" to="../">
                                    <button
                                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">

                                        <FontAwesomeIcon icon={faAngleLeft}/>
                                        &nbsp;
                                        {t('common:back')}
                                    </button>
                                </Link>

                                <InfoPopup/>

                                {cookies.session_info?.sessionID && cookies.session_info.evseID === evseID ?
                                    <Link type="button"
                                          to={"../success/session/" + cookies.session_info.sessionID}
                                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">

                                        Session Details &nbsp;<FontAwesomeIcon
                                        icon={loading ? faSpinner : faAngleRight}
                                        title="Session"/>
                                    </Link>
                                    : <button type="button"
                                              onClick={e => handleCheckout(e, captcha.execute)}
                                              disabled={!captcha.isReady}
                                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">

                                        {t('checkout:load')} &nbsp;<FontAwesomeIcon
                                        icon={loading ? faSpinner : faAngleRight}
                                        title={t('checkout:proceed_to_payment')}
                                        className={loading ? "fa-spin" : ""}/>
                                    </button>
                                }

                            </div>
                        </div>
                    </form>
                )}
            </ReCaptcha>
        </>
    )
}
export default CaptchaCheckoutForm;

