import Api from "../commom/api";
import {PricingDetailsInterface} from "../commom/types";

const priceAPI = async (evseID: string) => {

    const response = await Api.get<PricingDetailsInterface>('/evse/' + evseID + '/get-price-details/')

    return response.data
}

export {priceAPI}
